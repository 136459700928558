
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";

import TableExplorer from "@/components/TableExplorer.vue";
import moment from "moment/moment";
import Swal from "sweetalert2";
import { ref } from "vue";

export default defineComponent({
  name: "imports-table",
  components: {
    TableExplorer,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      typeToImport: ref(""),
      file: ref<File | null>(null),
      filters: {
        id: "",
        name: "",
        dateBetween: [
          moment().subtract(7, "days").format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        status: "",
        user: "",
      },
      columns: [
        {
          name: "id",
          label: "Id",
        },
        {
          name: "name",
          label: "Nombre archivo",
        },
        {
          name: "created_at",
          label: "Fecha",
        },
        {
          name: "created_by",
          label: "Usuario",
        },
        {
          name: "status",
          label: "Estado",
        }
      ],
      authorList: [],
      statusList: {
        completed: "Completado",
        pending: "Pendiente",
        failed: "Error",
      },
    }
  },
  mounted() {
    this.getAuthorsList();
  },
  methods: {
    getAuthorsList() {
      ApiService.query("index/users", {
        params: {},
      }).then((response) => {
        this.authorList = response.data;
      });
    },
    getData() {
      (this.$refs.importsTable as any).retrieveData();
    },
    clearFilters() {
      this.filters = {
        id: "",
        name: "",
        dateBetween: [
          moment().subtract(7, "days").format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        status: "",
        user: "",
      };
      this.getData();
    },
    removeKMLFile() {
      this.file = null;
    },
    changeKMLFile(data) {
      if (data.status === "ready") {
        this.file = data.raw as File
        if (!this.validateKML(this.file)) {
        Swal.fire({
          title: "Error",
          text: "El archivo seleccionado no es un archivo KML",
          icon: "error",
          confirmButtonText: "Aceptar",
        })
        this.file = null
        return
      }
      }
    },
    validateKML(file) {
      const validExtension = ".kml";
      const fileName = file.name.toLowerCase();
      return fileName.endsWith(validExtension);
    },
    processFile() {
      (this.$refs.upload as any).clearFiles();
      if (!this.file) {
        Swal.fire({
          title: "Error",
          text: "Debe seleccionar un archivo",
          icon: "error",
          confirmButtonText: "Aceptar",
        })
        return; 
      }
      Swal.fire({
        title: "Cargando",
        text: "Por favor espere...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          const formData = this.file;
          const reader = new FileReader();
          reader.readAsDataURL(formData as File);
          reader.onload = () => {
            const base64Data = reader.result
            ApiService.post("upload/polygons", {
              type: this.typeToImport,
              polygons: base64Data,
            })
              .then(() => {
              Swal.close();
              this.getData();
              this.typeToImport = ""
              this.file = null
              Swal.fire({
                title: "Archivo cargado",
                text: "El archivo se ha cargado correctamente",
                icon: "success",
                confirmButtonText: "Aceptar",
              })
            })
              .catch((e) => {
              Swal.close();
              Swal.fire({
                title: "Error",
                text: "Ha ocurrido un error al cargar el archivo",
                icon: "error",
                confirmButtonText: "Aceptar",
              })
            })
          };
          reader.onerror = (error) => {
            console.log("Error: ", error);
          };
        },
      });
    },
  },
});
