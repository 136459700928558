const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Donantes",
        route: "/donantes",
        svgIcon: "/media/icons/duotune/medicine/med005.svg",
        permission: "donor_view",
        fontIcon: "bi-layers",
      },
      {
        heading: "Reclamos",
        route: "/reclamos",
        svgIcon: "/media/icons/duotune/communication/com004.svg",
        permission: "donor_claims.view",
        fontIcon: "bi-layers",
      },
      {
        heading: "Análisis de Potencia",
        route: "/bidones",
        svgIcon: "/media/icons/duotune/medicine/med004.svg",
        fontIcon: "bi-layers",
        permission: "samples_update",
      },
      {
        heading: "Hoja de Ruta",
        route: "/ruta",
        svgIcon: "/media/icons/duotune/maps/map003.svg",
        fontIcon: "bi-layers",
        permission: "roadmap_view",
      },
      {
        heading: "Grupos",
        route: "/grupos",
        svgIcon: "/media/icons/duotune/general/gen025.svg",
        permission: "group.manage",
        fontIcon: "bi-people-fill",
      },
      {
        sectionTitle: "Solicitudes Pendientes",
        route: "/solicitudes_pendientes",
        svgIcon: "/media/icons/duotune/coding/cod005.svg",
        fontIcon: "bi-person",
        permission: "pending_requests.edit",
        sub: [
          {
            heading: "Cargas - hMG y hCG",
            permission: "pending_requests.edit",
            route: "/solicitudes_pendientes/carga",
          },
          {
            heading: "Precargas - hMG y hCG",
            permission: "pending_requests.edit",
            route: "/solicitudes_pendientes/precarga",
          },
          {
            heading: "Bajas",
            route: "/solicitudes_pendientes/bajas",
            permission: "pending_requests.edit",
          },
          {
            heading: "Suspensiones",
            route: "/solicitudes_pendientes/suspensiones",
            permission: "pending_requests.edit",
          },
          {
            heading: "Reactivaciones",
            route: "/solicitudes_pendientes/reactivaciones",
            permission: "pending_requests.edit",
          },
        ],
      },
      {
        heading: "Novedades",
        route: "/novedades",
        permission: "news.view",
        svgIcon: "/media/icons/duotune/coding/cod005.svg",
        fontIcon: "bi-people-fill",
      },
      {
        heading: "Motivos No Conformidad",
        route: "/motivos-no-conformidad",
        permission: "pending_requests.edit",
        svgIcon: "/media/icons/duotune/coding/cod011.svg",
        fontIcon: "bi-people-fill",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Perfiles",
        route: "/perfiles",
        svgIcon: "media/icons/duotone/Communication/Shield-thunder.svg",
        permission: "user_view.all",
        fontIcon: "bi-layers",
      },
      {
        heading: "Usuarios",
        route: "/usuarios",
        svgIcon: "media/icons/duotone/Files/User-folder.svg",
        fontIcon: "bi-layers",
        permission: "user_view.all",
      },
      {
        heading: "Transportistas",
        route: "/transportes",
        svgIcon: "/media/icons/duotune/ecommerce/ecm006.svg",
        fontIcon: "bi-layers",
        permission: "user_view.all",
      },
      {
        sectionTitle: "Poligonos",
        route: "/poligonos",
        svgIcon: "/media/icons/duotune/maps/map002.svg",
        fontIcon: "bi-map",
        permission: "polygons.edit",
        sub: [
          {
            heading: "Archivos KML",
            permission: "polygons.edit",
            route: "/poligonos/imports",
          }
        ]
      },
      {
        heading: "Auditoría",
        route: "/auditoria",
        permission: "audit_view",
        svgIcon: "media/icons/duotone/General/Binocular.svg",
        fontIcon: "bi-layers",
      },
    ],
  },
];

export default DocMenuConfig;
