
import { defineComponent, reactive, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import ContactModal from "@/components/modals/ContactModal.vue";
import DonorModal from "@/components/modals/DonorModal.vue";
import TransportModal from "@/components/modals/TransportModal.vue";
import DonorFilters from "@/components/DonorFilters.vue";
import { useStore } from "vuex";
import ElectronicSignature from "@/components/modals/ElectronicSignature.vue";
import { Modal } from "bootstrap";

import Swal from "sweetalert2/dist/sweetalert2.js";
import router from "@/router";
import Entitytable from "@/components/EntityTable.vue";
import Donorfiltersstatus from "@/components/DonorFiltersStatus.vue";
import TableExplorer from "@/components/TableExplorer.vue";
import store from "@/store";

export default defineComponent({
  name: "donors-table ",
  components: {
    TableExplorer,
    DonorFilters,
    DonorModal,
    ContactModal,
    ElectronicSignature,
    TransportModal,
  },
  props: {
    widgetClasses: String,
    transportFilter: Number,
  },
  data() {
    return {
      initialized: false,
      showFilters: true,
      orderBy: {
        field: "",
        direction: "ASC",
      },
      lastControlId: 0,
      batchAssignParams: {},
      checkedDonors: ref([]),
      statusOptions: {
        active: "Activa",
        inactive: "Inactiva",
      },
      exportingData: "off",
      exportingDataTransport: "off",
      exportingDataCsv: "off",

      transportList: [],
      collectorList: [],
      scheduleList: ["Lunes, miércoles y viernes"],
      userList: [],
      donors: null,
      page: 1,
      init: false,
      loading: false,
      perPage: 10,
      pages: null,
      count: 0,
      isPendingView: false,
    };
  },
  methods: {
    getAllowedFilters() {
      const filters = store.getters.getPermissions.includes("manage_transport")
        ? [
            "type",
            "status",
            "registration_date",
            "address",
            "state",
            "locality",
            "collector",
            "polygon"
          ]
        : [
            "type",
            "status",
            "registration_date",
            "termination_date",
            "termination_reason",
            "id",
            "firstName",
            "lastName",
            "dni",
            "birthdate",
            "updated_at",
            "created_at",
            "address",
            "state",
            "locality",
            "transport",
            "visitor",
            "polygon",
        ];
      if (this.isPendingView) {
        filters.splice(filters.indexOf("termination_reason"), 1)
        filters.splice(filters.indexOf("status"), 1)
      }

      return filters;
    },
    checkedChanged(params) {
      this.checkedDonors = params.checked;
    },
    retrieveData() {
      localStorage.setItem("donorFilters", JSON.stringify(this.filters));
      (this.$refs.donorTable as any).retrieveData();
    },
    updateFilters(newFilters) {
      this.filters = newFilters;
      this.retrieveData();
    },
    setOrderBy(field) {
      if (this.orderBy.field == field) {
        this.orderBy.direction =
          this.orderBy.direction == "ASC" ? "DESC" : "ASC";
      } else {
        this.orderBy.field = field;
        this.orderBy.direction = "ASC";
      }
      this.retrieveData();
    },
    checkCancelSubmit(signature) {
      ApiService.post("checks", {
        donorId: this.lastControlId,
        result: "cancel_others",
        electronicSignature: signature,
      }).then(() => {
        let myModal = document.getElementById("check-modal");
        if (myModal) {
          myModal.click();
        }
        router.push({
          name: "donor-entity",
          params: { id: this.lastControlId },
        });
      });
    },
    performControl(donorId) {
      this.lastControlId = donorId;
      Swal.fire({
        title: "Control de Activa",
        width: 500,
        text: "¿La donante respondió satisfactoriamente las preguntas del cuestionario?",
        icon: "question",
        confirmButtonText: "Sí",
        showConfirmButton: true,
        showDenyButton: true,
        showCancelButton: true,
        denyButtonText: "No",
        cancelButtonText: "Cancelar",
      })
        .then((result) => {
          if (result.isConfirmed) {
            ApiService.post("checks", {
              donorId: donorId,
              result: "ok",
              extra: "",
            }).then(() => {
              let myModal = document.getElementById("check-modal");
              if (myModal) {
                myModal.click();
              }
              router.push({ name: "donor-edit", params: { id: donorId } });
            });
          } else if (result.isDenied) {
            Swal.fire({
              title: "Confirmación de baja",
              text: "Se procederá a dar de baja la donante, ¿desea continuar?",
              showDenyButton: true,
              confirmButtonText: "Si",
              denyButtonText: "No",
            }).then((result) => {
              if (result.isConfirmed) {
                let modalito = new Modal(
                  document.getElementById("checkCancelSignature")
                );
                modalito.show();
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStatusIcon(donor) {
      switch (donor.status) {
        case "active":
          return "las la-user-check";
        case "inactive":
          return "las la-user-times";
        case "suspended":
          return "las la-user-clock";
      }
    },
    getStatusClass(donor) {
      switch (donor.status) {
        case "active":
          return "text-success";
        case "inactive":
          return "text-danger";
        case "suspended":
          return "text-warning";
        default:
        case "irrecoverable":
          return "svg-icon-secondary";
      }
    },
    formatNumber(number) {
      return Number(number)
        .toString()
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
    },
    reassignTransport() {
      Swal.fire({
        title: "Reasignación masiva",
        text:
          "Seleccione el transportista al cual reasignará " +
          this.checkedDonors.length +
          " donante(s): ",
        input: "select",
        customClass: {
          input: "form-control form-control-solid",
        },
        confirmButtonText: "Reasignar",
        cancelButtonText: "Cancelar",
        inputOptions: this.transportList,
        inputPlaceholder: "Seleccione",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Debe elegir un transportista al cual asignar";
          }

          return null
        },
        preConfirm: (value) => {
          this.batchAssignParams = {
            transportId: value,
            donors: this.checkedDonors,
          };
          this.triggerSignatureForm();
        },
      });
    },
    reassignTransportWithSignature: function (signature) {
      ApiService.post("donors/batch/assign", {
        ...this.batchAssignParams,
        signature: signature,
      }).then((response) => {
        if (response.data.success === true) {
          (this.$refs.donorTable as any).clearChecked();
          this.retrieveData();
        }
      });
    },
    triggerSignatureForm() {
      let modalito = new Modal(
        document.getElementById("reassignTransportSignatureModal")
      );
      modalito.show();
    },
    reassignCollector() {
      Swal.fire({
        title: "Reasignación masiva",
        text:
          "Seleccione el recolector al cual reasignará " +
          this.checkedDonors.length +
          " donante(s): ",
        input: "select",
        customClass: {
          input: "form-control form-control-solid",
        },
        confirmButtonText: "Reasignar",
        cancelButtonText: "Cancelar",
        inputOptions: this.collectorList,
        inputPlaceholder: "Seleccione",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Debe elegir un recolector al cual asignar";
          }

          return null
        },
        preConfirm: (value) => {
          ApiService.post("donors/batch/assign", {
            collectorId: value,
            donors: this.checkedDonors,
          }).then((response) => {
            if (response.data.success === true) {
              (this.$refs.donorTable as any).clearChecked();
              this.retrieveData();
            }
          });
        },
      });
    },
    reassignSchedule() {
  Swal.fire({
    title: "Cambio de día de recolección",
    html: `
      <div style="display: flex; flex-wrap: wrap; gap: 1rem;">
        <label>
          <input type="checkbox" id="monday" value="1" style="margin-right: 5px;"> Lunes
        </label>
        <label>
          <input type="checkbox" id="tuesday" value="2" style="margin-right: 5px;"> Martes
        </label>
        <label>
          <input type="checkbox" id="wednesday" value="3" style="margin-right: 5px;"> Miércoles
        </label>
        <label>
          <input type="checkbox" id="thursday" value="4" style="margin-right: 5px;"> Jueves
        </label>
        <label>
          <input type="checkbox" id="friday" value="5" style="margin-right: 5px;"> Viernes
        </label>
        <label>
          <input type="checkbox" id="saturday" value="6" style="margin-right: 5px;"> Sábado
        </label>
      </div>
    `,
    showCancelButton: true,
    cancelButtonText: "Cancelar",
    confirmButtonText: "Reasignar",
    preConfirm: () => {
      const selectedDays: number[] = [];
      if ((document.getElementById("monday") as HTMLInputElement)?.checked)
        selectedDays.push(1);
      if ((document.getElementById("tuesday") as HTMLInputElement)?.checked)
        selectedDays.push(2);
      if ((document.getElementById("wednesday") as HTMLInputElement)?.checked)
        selectedDays.push(3);
      if ((document.getElementById("thursday") as HTMLInputElement)?.checked)
        selectedDays.push(4);
      if ((document.getElementById("friday") as HTMLInputElement)?.checked)
        selectedDays.push(5);
      if ((document.getElementById("saturday") as HTMLInputElement)?.checked)
        selectedDays.push(6);

      return selectedDays;
    },
  }).then((result) => {
    if (result.isConfirmed) {
    console.log(result)
      ApiService.post("donors/batch/assign", {
        scheduleDays: result.value,
        donors: this.checkedDonors,
      }).then((response) => {
        if (response.data.success === true) {
          (this.$refs.donorTable as any).clearChecked();
          this.retrieveData();
        }
      });
    }
  });
},
  },
  mounted() {
    ApiService.get("index", "transports").then((response) => {
      this.transportList = response.data;
    });

    ApiService.get("index", "collectors").then((response) => {
      this.collectorList = response.data;
    });

    ApiService.query("index/users", {
      params: { permission: ["place_visit"] },
    }).then((response) => {
      this.userList = response.data;
    });

    if (this.$route.params.transportFilter) {
      this.filters.transport = this.$route.params.transportFilter.toString();
    }

    if (this.autoSearch) {
      this.retrieveData();
    }

    this.isPendingView = this.$route.name === "solicitudes-carga";
  },

  setup() {
    const autoSearch = ref(false);

    const filters = reactive({
      type: "",
      registrationDate: [],
      terminationDate: [],
      terminationReasonId: [],
      status: "",
      id: "",
      transport: "",
      string: "",
      visitorId: null,
      schedule: null,
      address_number: "",
      address: "",
      dni: "",
      birthdate: "",
      created_at: [],
      updated_at: [],
      localityId: null,
      collectorId: null,
      stateId: null,
      lastName: "",
      firstName: "",
      polygon: "",
    });

    const store = useStore();
    let previousFilters = localStorage.getItem("donorFilters");

    if (previousFilters) {
      autoSearch.value = true;
      let previousValues = JSON.parse(previousFilters);
      if (previousValues.type) filters.type = previousValues.type;

      if (previousValues.registrationDate)
        filters.registrationDate = previousValues.registrationDate;

      if (previousValues.terminationDate)
        filters.terminationDate = previousValues.terminationDate;
      if (previousValues.created_at)
        filters.created_at = previousValues.created_at;

      if (previousValues.terminationReasonId)
        filters.terminationReasonId = previousValues.terminationReasonId;

      if (previousValues.status) filters.status = previousValues.status;

      if (previousValues.id) filters.id = previousValues.id;

      if (previousValues.transport)
        filters.transport = previousValues.transport;

      if (previousValues.string) filters.string = previousValues.string;

      if (previousValues.visitorId)
        filters.visitorId = previousValues.visitorId;

      if (previousValues.schedule) filters.schedule = previousValues.schedule;

      if (previousValues.address_number)
        filters.address_number = previousValues.address_number;

      if (previousValues.address) filters.address = previousValues.address;

      if (previousValues.dni) filters.dni = previousValues.dni;

      if (previousValues.birthdate)
        filters.birthdate = previousValues.birthdate;

      if (previousValues.updated_at)
        filters.updated_at = previousValues.updated_at;

      if (previousValues.localityId)
        filters.localityId = previousValues.localityId;

      if (previousValues.stateId) filters.stateId = previousValues.stateId;

      if (previousValues.lastName) filters.lastName = previousValues.lastName;

      if (previousValues.firstName)
        filters.firstName = previousValues.firstName;
    }

    return { store, filters, autoSearch };
  },
});
