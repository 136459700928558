
import { defineComponent, reactive, ref, watch, defineEmits } from "vue";
import ApiService from "@/core/services/ApiService";
import ContactModal from "@/components/modals/ContactModal.vue";
import DonorModal from "@/components/modals/DonorModal.vue";
import TransportModal from "@/components/modals/TransportModal.vue";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { GoogleMap, Marker, InfoWindow } from "vue3-google-map";
import { array } from "yup";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "TableExplorer",
  template: "#table-explorer",
  components: {
    GoogleMap,
    Marker,
    InfoWindow,
  },
  props: {
    filterData: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    resourceName: {
      type: String,
      required: true,
    },
    overrideColumns: {
      type: Array,
      default: () => {
        return [];
      },
      required: false,
    },
    routePrefix: {
      type: String,
      required: true,
    },
    allowSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    showTable: {
      type: Boolean,
      required: false,
      default: true,
    },
    showInfoWindow: {
      type: Boolean,
      required: false,
      default: false,
    },
    showMarkerInfoWindow: {
      type: Boolean,
      required: false,
      default: false,
    },
    showMarkers: {
      type: Boolean,
      required: false,
      default: true,
    },
    showPagination: {
      type: Boolean,
      required: false,
      default: true,
    },
    retrieveOnMount: {
      type: Boolean,
      required: false,
      default: true,
    },
    linkView: {
      type: Boolean,
      required: false,
      default: false,
    },
    linkColumnIndex: {
      type: Number,
      required: false,
      default: 1,
    },
    position: {
      type: Object,
      required: false,
      default: () => {
        return {
          lat: null,
          lng: null,
        };
      },
    },
    paginationLayout: {
      type: String,
      default: () => {
        return "prev, pager, next";
      },
    },
    showQuickfilter: Boolean,
    showCreate: Boolean,
    showExport: Boolean,
    showEdit: Boolean,
    showActions: Boolean,
    showMap: Boolean,
    showDelete: Boolean,
    widgetClasses: String,
  },
  computed: {
    entitiesInMap() {
      return this.entities.filter((entity) => {
        return typeof entity.location.type != "undefined";
      });
    },
  },
  data() {
    return {
      key: process.env.VUE_APP_MAPS_API_KEY,
      initialized: false,
      mapStyle: [
        {
          featureType: "transit",
          stylers: [
            {
              color: "#808080",
            },
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
      filters: {
        string: "",
      },
      columnsData: reactive<any>([]),
      perPage: 20,
      orderBy: {
        field: "",
        direction: "",
      },
      exportingData: "",
      entities: reactive<any>([]),
      selectedAll: false,
      stringFilter: "",
      loading: false,
      page: 1,
      pages: [],
      count: 0,
      roles: {
        transport_manager: {
          name: "Transportista",
          icon: "media/icons/duotone/Shopping/Box3.svg",
        },
        transport_visitor: {
          name: "Visitador",
          icon: "media/icons/duotone/Clothes/Shirt.svg",
        },
      },
    };
  },
  methods: {
    handleSizeChange(val: number) {
      this.perPage = val;
      this.retrieveData();
    },
    handleCurrentChange(val: number) {
      this.page = val;
      this.retrieveData();
    },
    toggleSelectAll() {
      this.entities.forEach((entity) => {
        if (entity.status == "inactive") return;
        if (this.selectedAll) {
          let index = this.checkedEntities.indexOf((entity as any).id);
          if (index > -1) {
            this.checkedEntities.splice(index, 1); // 2nd parameter means remove one item only
          }
        } else {
          let index = this.checkedEntities.indexOf((entity as any).id);
          if (index == -1) {
            this.checkedEntities.push((entity as any).id);
          }
        }
      });
      this.$emit("onCheckedChanged", { checked: this.checkedEntities });
      this.selectedAll = !this.selectedAll;
    },
    setOrderBy(field) {
      if (this.orderBy.field == field) {
        this.orderBy.direction =
          this.orderBy.direction == "ASC" ? "DESC" : "ASC";
      } else {
        this.orderBy.field = field;
        this.orderBy.direction = "ASC";
      }
      this.retrieveData();
    },
    gotoEntity(entity) {
      this.scrollTop();
      this.panTo(entity);
    },
    exportData() {
      Swal.fire({
        title: "Exportación en progreso",
        html: "",
        timer: 300000,
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          ApiService.query("export/" + this.resourceName, {
            responseType: "blob",
            params: {
              lat: this.position.lat,
              lng: this.position.lng,
              orderBy: this.orderBy,
              perPage: this.perPage,
              page: this.page,
              filters: this.filterData,
              filterString: this.filters.string,
            },
          })
            .then((response) => {
              Swal.close();
              const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = this.routePrefix + ".xlsx";
              link.click();
            })
            .catch((error) => {
              Swal.fire({
                title: "Ocurrió un error al exportar los datos",
                text: "Compruebe que la cantidad de resultados sea inferior a 12.000 y vuelva a intentarlo.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Cerrar",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        },
      });
    },

    getStatusClass(status) {
      switch (status) {
        case "active":
          return "svg-icon-success";
        case "inactive":
          return "svg-icon-danger";
      }
    },
    triggerSearch(instant) {
      setTimeout(
        () => {
          this.page = 1;
          this.filters.string = this.stringFilter;
          this.retrieveData();
        },
        instant ? 10 : 400
      );
    },
    gotoPage(pageNumber) {
      this.page = pageNumber;
      this.retrieveData();
    },
    handleDelete(id) {
      Swal.fire({
        title: "Confirmación de baja",
        text: "Se procederá a eliminar un elemento, ¿desea continuar?",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete(this.resourceName + "/" + id.toString()).then(
            () => {
              this.retrieveData();
            }
          );
        }
      });
    },
    clearChecked() {
      this.checkedEntities = [];
    },
    retrieveData() {
      this.initialized = true;
      this.loading = true;
      ApiService.query(this.resourceName, {
        params: {
          lat: this.position.lat,
          lng: this.position.lng,
          orderBy: this.orderBy,
          perPage: this.showPagination ? this.perPage : null,
          page: this.page,
          filters: this.filterData,
          filterString: this.filters.string,
        },
      })
        .then((response) => {
          this.$emit("onRetrieve", response.data);
          this.loading = false;
          if (
            response.data.columns &&
            (this.overrideColumns as any).length == 0
          ) {
            this.columnsData = response.data.columns;
          } else {
            this.columnsData = this.overrideColumns;
          }
          this.page = response.data.meta.current_page;
          this.entities = response.data.data;
          this.count = response.data.meta.total;
          this.perPage = parseInt(response.data.meta.per_page);
          this.selectedAll = true;
          this.entities.forEach((entity) => {
            let index = this.checkedEntities.indexOf((entity as any).id);
            if (index == -1) {
              this.selectedAll = false;
            }
          });

          let bounds = new this.mapRef.api.LatLngBounds();
          let multiple = false;

          if (
            this.position.lat &&
            this.position.lng &&
            typeof this.position.lat == "number" &&
            typeof this.position.lng == "number"
          ) {
            bounds.extend({
              lat: this.position.lat,
              lng: this.position.lng,
            });
          }

          if (this.entitiesInMap) {
            for (let i = 0; i < this.entitiesInMap.length; i++) {
              multiple = true;
              bounds.extend({
                lat: (this.entities as any)[i].location.coordinates[1],
                lng: (this.entities as any)[i].location.coordinates[0],
              });
            }
          }

          this.mapRef.map.fitBounds(bounds);

          this.mapRef.maps.addListenerOnce(
            this.mapRef.map,
            "bounds_changed",
            (event) => {
              this.mapRef.map.setZoom(this.mapRef.map.getZoom() - 1);

              if (this.mapRef.map.getZoom() > 15) {
                this.mapRef.map.setZoom(15);
              }
            }
          );
        })
        .catch((e) => {
          this.loading = false;
        });
    },
  },
  emits: ["onRetrieve", "onCheckedChanged"],
  mounted() {
    if (this.retrieveOnMount) {
      this.retrieveData();
    }
  },
  setup(props, { emit }) {
    const router = useRouter();
    const i18n = useI18n();

    i18n.locale.value = "es";

    const checkedEntities = ref<any>([]);
    const mapRef = ref();
    const store = useStore();
    let bounds;

    watch(checkedEntities, () => {
      emit("onCheckedChanged", { checked: checkedEntities.value });
    });

    watch(
      () => mapRef.value?.ready,
      (ready) => {
        if (!ready) return;

        bounds = new mapRef.value.api.LatLngBounds();
        mapRef.value.map.setZoom(3);
      }
    );

    const scrollTop = () => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };

    const panTo = (entity) => {
      mapRef.value.map.panTo({
        lat: entity.location.coordinates[1],
        lng: entity.location.coordinates[0],
      });
      mapRef.value.map.setZoom(18);
    };

    return { mapRef, store, bounds, panTo, checkedEntities, i18n, scrollTop };
  },
});
